import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import CustomersService from '../../../services/CustomersService';
import { CInput } from '@coreui/react';
import { Controller, useForm } from 'react-hook-form';
import DataRow from '../../../components/DataRow/DataRow';
import Checkbox from '../../../components/Checkbox/Checkbox';
import MartialSelect from '../../../components/MartialSelect/MartialSelect';
import Button from '../../../components/Button/Button';
import Loader from '../../../components/Loader/Loader';
import { toast } from 'react-toastify';
import { removeEmptyStringsAndNulls } from '../../../helpers';

const IncomeAnalysisNP = ({ income_analysis, customerId }) => {
  const [updateIncomeCalculatorNP, { isLoading }] = useMutation(
    CustomersService.updateIncomeCalculatorNP(customerId),
    {
      onSuccess: () => {
        toast.success('Pomyślnie zaktualizowano dane.');
      },
      onError: (error) => {
        const isArrError = !!error?.response?.data?.errors;
        const isSingleError = !!error?.response?.data?.data;

        if (isArrError) {
          toast.error('Ups... Coś poszło nie tak...');
          const errors = error.response.data.errors;
          errors.forEach(({ invalid_property, message }) => {
            setError(invalid_property, {
              type: 'manual',
              message: message,
            });
          });
        }
        if (isSingleError) {
          toast.error(
            error?.response?.data?.data || 'Ups... Coś poszło nie tak...'
          );
        }
      },
    }
  );

  const { register, handleSubmit, control, reset, setError, errors } = useForm({
    defaultValues: {
      liabilities_count: '',
      marital_status: '',
      net_income: '',
      number_of_dependents: '',
      property_separation: true,
      balance: '',
      net_month_liabilities_cost: '',
      net_month_living_cost: '',
      net_total_expenses: '',
      total_debt: '',
    },
  });

  useEffect(() => {
    reset({
      ...income_analysis,
      net_income: income_analysis.net_month_income,
      marital_status: income_analysis.marital_status_symbol,
    });
  }, [income_analysis, reset]);

  const onSubmit = (values) => {
    updateIncomeCalculatorNP(removeEmptyStringsAndNulls(values));
  };

  return (
    <>
      {isLoading && <Loader show />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <DataRow
          label="Dochód gosp.dom netto / mc"
          value={<CInput name="net_income" innerRef={register} />}
          InvalidInput={errors?.net_income?.message}
        />
        <DataRow
          label="Liczba osób w gosp. dom."
          value={<CInput name={'number_of_dependents'} innerRef={register} />}
          InvalidInput={errors?.number_of_dependents?.message}
        />
        <DataRow
          label="Rodzielność majątkowa"
          value={<Checkbox name={'property_separation'} ref={register} />}
          InvalidInput={errors?.property_separation?.message}
        />
        <DataRow
          label="Liczba zobowiązań"
          value={<CInput name={'liabilities_count'} innerRef={register} />}
          InvalidInput={errors?.liabilities_count?.message}
        />
        <DataRow
          label="Stan cywilny"
          InvalidInput={errors?.marital_status?.message}
          value={
            <Controller
              control={control}
              name={'marital_status'}
              render={({ onChange, value }) => (
                <MartialSelect
                  onChange={onChange}
                  value={value}
                  name={'marital_status'}
                />
              )}
            />
          }
        />
        {/* ------------------ */}
        <DataRow
          label="Koszty utrzymania / mc"
          value={income_analysis?.net_month_living_cost}
        />
        <DataRow
          label="Ilość zobowiązań"
          value={income_analysis.liabilities_count}
        />
        <DataRow label="Łączne zadłużenie" value={income_analysis.total_debt} />
        <DataRow
          label="Obsługa zadłużenia / mc"
          value={income_analysis.net_month_liabilities_cost}
        />
        <DataRow
          label="Łączne wydatki / mc"
          value={income_analysis.net_total_expenses}
        />
        <DataRow
          containerClass="bg-light-grey"
          label="Nadwyżka / niedobór / mc"
          value={income_analysis.balance}
        />
        <Button className="w-100 mt-2" type="submit">
          Wyślij
        </Button>
      </form>
    </>
  );
};

export default IncomeAnalysisNP;
