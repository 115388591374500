import React from 'react';
import { CUSTOMER_TYPE } from '../../../variables';
import IncomeAnalysisNP from './IncomeAnalysisNP';
import IncomeAnalysisIE from './IncomeAnalysisIE';
import IncomeAnalysisLP from './IncomeAnalysisLP';

const IncomeAnalysis = ({ customerType, income_analysis, customerId }) => {
  switch (customerType) {
    case CUSTOMER_TYPE.NATURAL_PERSON.SYMBOL:
      return (
        <IncomeAnalysisNP
          income_analysis={income_analysis}
          customerId={customerId}
        />
      );
    case CUSTOMER_TYPE.INDIVIDUAL_ENTREPRENEUR.SYMBOL:
      return (
        <IncomeAnalysisIE
          income_analysis={income_analysis}
          customerId={customerId}
        />
      );
    case CUSTOMER_TYPE.LEGAL_PERSON.SYMBOL:
      return (
        <IncomeAnalysisLP
          income_analysis={income_analysis}
          customerId={customerId}
        />
      );
    default:
      return <></>;
  }
};

export default IncomeAnalysis;
