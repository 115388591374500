import React from 'react';
import ReactSelect from 'react-select';
import { MARTIAL_STATUS } from '../../variables';

const MartialSelect = ({ name, onChange, value, ...props }) => {
  const options = [
    {
      label: MARTIAL_STATUS.MARRIED_FEMALE.NAME_PL,
      value: MARTIAL_STATUS.MARRIED_FEMALE.SYMBOL,
    },
    {
      label: MARTIAL_STATUS.MARRIED_MALE.NAME_PL,
      value: MARTIAL_STATUS.MARRIED_MALE.SYMBOL,
    },
    {
      label: MARTIAL_STATUS.OTHER.NAME_PL,
      value: MARTIAL_STATUS.OTHER.SYMBOL,
    },
  ];

  return (
    <ReactSelect
      className={'w-100'}
      name={name}
      options={options}
      onChange={(e) => onChange(e.value)}
      value={options.find((item) => item.value === value)}
      {...props}
    />
  );
};

export default MartialSelect;
